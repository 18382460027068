/* universal widget heading */
.widget-heading {
    padding-bottom: 15px;
    color: $black;
    font-family: $proxima;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.3;

    @media (max-width: 600px) {
        padding-bottom: 0;
        font-size: 24px;
    }
}

.widget-section {
    line-height: 0.85rem;
    margin: 0;
    padding: 0;

    font-weight: 700;
    letter-spacing: 1px;
    font-size: 0.85rem;
    text-decoration: none;
    &.upper {
        text-transform: uppercase;
    }
    a {
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        letter-spacing: 1px;
        font-size: 0.85rem;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

/* setting the in-article pixel field to a 1x1 */
.widget-article-pixel {
    img {
        width: 1px !important;
        height: 1px !important;
    }
}

/* featured stories: list selection */
.widget-generic-article-list {
    .widget-article-list-item {
        display: grid;
        grid-template-columns: 250px 1fr;
        grid-gap: 30px;
        align-items: center;
        margin: 2rem 0;
        border-bottom: 1px solid #cccccc;
        padding: 0 0 2rem 0;
        &:first-of-type {
            margin-top: 0;
            border-top: 0;
        }
        &:last-of-type {
            margin-bottom: 0;
            border-bottom: 0;
            padding-bottom: 0;
        }
        .widget-item-image {
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
        .widget-item-text {
            .section {
                max-width: 100%;
                margin: 0;
                a {
                    text-decoration: none;
                }
            }
            h3 {
                max-width: 100%;
                margin: 1rem auto;
                font-weight: 300;
                a {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .article-author {
                line-height: 1.7;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 0.95rem;
                margin: 0;
                a {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

/* call to action plugin: used in featured stories and grids */
.widget-call-to-action-small {
    background: #f5f4f4;
    text-align: left;
    padding: 15px;

    .widget-container {
        border-top: 1px solid $divider;
        border-bottom: 1px solid $divider;
    }

    h3 {
        font-family: $minion-condensed;
        font-size: 25px;
        font-style: italic;
        font-weight: 500;
        line-height: 1.2;
        margin: 0;
    }

    img {
        width: 100%;
        height: auto;
        display: block;
        padding: 16px 0;
    }

    .section {
        font-family: $proxima;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.3;
        margin: 0 0 7px 0;
    }
}

/* call to action plugin: se right rail promotion */
/* need to revisit this */
.widget-call-to-action-se-promo {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-gap: 25px;
    align-items: center;
    img {
        width: 100%;
        height: auto;
        display: block;
    }
    h3 {
        font-weight: 400 !important;
        margin-top: 0;
        font-size: 1.2rem !important;
    }
    button {
        border: 3px solid $black;
        background: $black;
        color: $white;
        &:hover {
            background: $white;
            color: $black;
            a {
                color: $black;
            }
        }
        a {
            color: $white;
            text-transform: uppercase;

            font-weight: 700;
            letter-spacing: 1px;
            display: block;
            padding: 0.4rem 2rem;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

/* most popular: right rail */
.widget-most-popular {
    padding: 0 20px 20px;
    &.larger {
        margin-top: 0;
    }
    ol {
        margin: 0 0 0 15px;
        padding: 0;
        line-height: inherit;
        li {
            padding-bottom: 10px;
            &:last-of-type {
                padding-bottom: 0;
            }
            &::marker {
                font-weight: 300;
                font-size: 2.5rem;
                vertical-align: top;
                position: relative;
                line-height: 1;
            }
            .containment {
                display: inline-grid;
                grid-template-columns: 76px 1fr;
                grid-gap: 15px;
                vertical-align: middle;
                padding: 5px 0;
                .thumb {
                    width: 76px;
                    height: 76px;
                    img {
                        width: 100%;
                    }
                }
                .headline {
                    font-weight: 400;
                    margin: 0;
                    padding: 0;
                    line-height: 1.3;
                    font-size: 0.9rem;

                    a {
                        border: 0;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

/* blog stream: list with no images */
/* there are a lot of article styles that force many important tags */
/* need to revisit this */
.widget-blog-list-no-images {
    h3 {
        font-family: $minion-condensed;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        margin: 5px 0 32px;
        a {
            text-decoration: none !important;
            &:hover {
                text-decoration: underline !important;
                color: $black !important;
            }
        }
    }
    ul {
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;

        li {
            list-style-type: none;
            padding: 0 20px 8px 0;
            color: $black;
            font-family: $proxima;
            font-size: 18px;
            font-weight: 500;
            line-height: 1.4;
            display: inline-block;

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: none !important;
                    border-bottom: 0;
                    text-decoration-thickness: 0 !important;
                }
            }
        }
    }
}

.widget-blog-wrapper {
    max-width: $desktop_large;
    width: auto;
    margin: 0 auto;
    padding: 0 56px 200px;

    @media (max-width: 1200px) {
        padding: 0 32px 100px;
    }

    @media (max-width: 600px) {
        padding: 0 32px 100px;
    }

    #voicesLogo {
        width: 400px;
        margin: 56px 0 40px;

        @media (max-width: 600px) {
            width: auto;
            max-width: 100%;
            box-sizing: border-box;
        }
    }
}

/* blogs: main screen featured items */
.widget-featured-blogs {
    padding-bottom: 80px;
    border-bottom: 1px solid #ccc;
    list-style-type: none;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 48px;

    @media (max-width: 1200px) {
        grid-template-columns: 1fr;
    }

    @media (max-width: 800px) {
        display: block;
    }

    @media (max-width: 600px) {
        margin-top: 10px;
    }

    .widget-featured-blogs-item {
        display: grid;
        grid-template-columns: 314px 1fr;
        gap: 48px;

        @media (max-width: 800px) {
            margin-bottom: 0;
            display: block;
        }

        h3 {
            margin: 0;
            font-family: $minion-condensed;
            font-size: 30px;
            font-weight: 500;
            line-height: 1.3;
        }

        p.widget-section {
            margin: 0;

            @media (max-width: 800px) {
                margin-top: 5px;
            }

            a {
                font-family: $proxima;
                font-size: 18px;
                line-height: 1.3;
                text-transform: none;
                letter-spacing: 0;
            }
        }

        p.dek {
            margin: 5px 0 10px;
            font-family: $minion-condensed;
            font-size: 22px;
            font-weight: 400;
            line-height: 1.3;
        }

        p.author,
        p.published {
            margin-top: 0;
            color: $digital-grey;
            font-family: $proxima;
            font-size: 16px;
            font-weight: 500;
            display: inline-block;

            a {
                color: $digital-grey;
            }
        }

        p.published {
            margin-left: 5px;
        }

        &:first-child {
            grid-column: 1;
            grid-row: 1 / last-line;
            grid-row-start: span 3;
            display: block;
            position: relative;
            z-index: 3;

            p.widget-section {
                margin-top: 25px;

                @media (max-width: 800px) {
                    margin-top: 5px;
                }
            }
        }

        &:empty {
            display: none;
        }
    }

    img {
        width: 100%;
        height: auto;
    }
}

/* widget for two column pages (right rail) */
.widget-page-right-column {
    padding-top: 80px;
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 48px;

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
    }

    .section-title {
        color: $black;
        font-family: $proxima;
        font-size: 28px;
        font-weight: 700;
        line-height: 1.3;
    }

    .widget-blog-posting-list {
        margin: 20px 0 40px;
        display: grid;
        grid-template-columns: 314px 1fr;
        gap: 48px;

        @media (max-width: 800px) {
            margin-bottom: 0;
            display: block;
        }

        .widget-blog-post-text {
            h3 {
                margin: 0;
                font-family: $minion-condensed;
                font-size: 30px;
                font-weight: 500;
                line-height: 1.3;
            }

            p.widget-section {
                margin: 0;

                @media (max-width: 800px) {
                    margin-top: 5px;
                }

                a {
                    font-family: $proxima;
                    font-size: 18px;
                    line-height: 1.3;
                    text-transform: none;
                    letter-spacing: 0;
                }
            }

            p.dek {
                margin: 5px 0 10px;
                font-family: $minion-condensed;
                font-size: 22px;
                font-weight: 400;
                line-height: 1.3;
            }

            .author,
            .published {
                margin-top: 0;
                margin-bottom: 0;
                color: $digital-grey;
                font-family: $proxima;
                font-size: 16px;
                font-weight: 500;
                line-height: 1;
                display: inline-block;

                a {
                    color: $digital-grey;
                }
            }

            p.published {
                margin-left: 5px;
            }

            &:first-child {
                grid-column: 1;
                grid-row: 1 / last-line;
                grid-row-start: span 3;
                display: block;
                position: relative;
                z-index: 3;

                p.widget-section {
                    margin-top: 25px;
                }
            }

            &:empty {
                display: none;
            }
        }
        .widget-blog-post-image {
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
    }
}
