.widget-blog-wrapper {
    @media (max-width: 600px) {
        padding: 0 0 100px;
    }

    .widget-blog-posting-list {
        display: grid;
        grid-template-areas: 'left right';

        .widget-blog-post-image {
            grid-area: left;
        }

        .widget-blog-post-text {
            grid-area: right;

            p.widget-section {
                @media (max-width: 600px) {
                    margin-top: -5px;
                    font-size: 16px;

                    a {
                        font-size: 16px;
                    }
                }
            }

            h3 {
                @media (max-width: 600px) {
                    font-size: 20px;
                }
            }

            p.dek {
                @media (max-width: 600px) {
                    display: none;
                }
            }

            .author,
            .pub-date {
                @media (max-width: 600px) {
                    font-size: 14px;
                }
            }
        }

        @media (max-width: 800px) {
            grid-template-columns: 1fr 123px;
            gap: 17px;

            .widget-blog-post-image {
                grid-area: right;
            }

            .widget-blog-post-text {
                grid-area: left;
            }
        }
    }

    .widget-blog-post-text {
        .author,
        .pub-date {
            @media (max-width: 600px) {
                margin-right: 5px;
                display: inline-block;
            }
        }
    }

    #voicesLogo,
    .widget-featured-blogs-item,
    .widget-page-right-column {
        padding: 0 32px;
    }

    .widget-page-right-column {
        padding-top: 80px;
    }

    .widget-featured-blogs-item:first-child {
        padding: 0;

        .widget-blog-post-text {
            padding: 0 32px;
        }
    }
}

.list-template {
    &.voices {
        .main-content {
            max-width: $desktop_large;
            margin-top: 56px;

            &.content-list {
                .voices-featured-main {
                    margin-bottom: 80px;

                    .voicesLogo {
                        text-align: right;

                        img {
                            width: 100%;
                            height: auto;

                            @media (max-width: 1000px) {
                                width: 300px;
                            }

                            @media (max-width: 600px) {
                                width: 100%;
                            }
                        }
                    }

                    .voicesIntro {
                        display: grid;
                        grid-template-columns: 1fr 310px;
                        gap: 24px;
                        align-items: center;
                        padding-bottom: 40px;

                        @media (max-width: 1000px) {
                            display: block;
                        }

                        p {
                            margin: 0;
                            padding: 0;
                        }

                        .section {
                            font-family: $proxima;
                            font-size: 36px;
                            font-weight: 700;
                            line-height: 1.3;

                            @media (max-width: 600px) {
                                font-size: 24px;
                            }
                        }
                    }
                    .voices-featured {
                        padding-bottom: 80px;
                        display: grid;
                        grid-template-columns: 2fr 3fr;
                        gap: 48px;

                        @media (max-width: 1100px) {
                            display: block;
                        }

                        h2 {
                            font-family: $minion-condensed;
                            font-size: 30px;
                            font-weight: 500;
                            line-height: 1.3;
                            margin: 24px 0 0;
                        }

                        p {
                            margin: 10px 0 0 0;
                            padding: 0;
                            font-family: $minion-condensed;
                            font-size: 22px;
                            font-weight: 400;
                            line-height: 1.3;

                            &.author {
                                font-family: $proxima;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 1.4;
                                color: $digital-grey;

                                a {
                                    color: $digital-grey;
                                }
                            }
                        }

                        img {
                            width: 100%;
                            height: auto;
                            display: block;
                        }

                        .voices-sub-featured {
                            @media (max-width: 1100px) {
                                margin-top: 56px;
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 50px;
                            }

                            @media (max-width: 800px) {
                                display: block;
                            }

                            .voices-featured-item {
                                display: grid;
                                grid-template-columns: 314px 1fr;
                                gap: 48px;

                                @media (max-width: 1100px) {
                                    display: block;
                                }

                                &:first-of-type {
                                    margin-bottom: 40px;
                                }

                                h2 {
                                    margin: -9px 0 0;
                                    position: relative;
                                }

                                p.dek {
                                    @media (max-width: 1100px) {
                                        display: none;
                                    }
                                }

                                .post-list-img {
                                    @media (max-width: 1100px) {
                                        margin-bottom: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .list-columns {
                margin-top: 80px;
                display: grid;
                grid-template-columns: 1fr 300px;
                gap: 48px;

                @media (max-width: 1000px) {
                    display: block;
                }

                &.secondary-pages-columns {
                    margin-top: 0;
                }

                .listLeft {
                    .widget-blog-post-container {
                        .widget-blog-post-wrapper {
                            margin-bottom: 40px;
                            display: grid;
                            grid-template-columns: 314px 1fr;
                            grid-template-areas: 'left right';
                            gap: 48px;

                            @media (max-width: 800px) {
                                grid-template-columns: 200px 1fr;
                                gap: 20px;
                            }

                            @media (max-width: 600px) {
                                grid-template-columns: 1fr 123px;
                            }

                            .widget-blog-post-image {
                                @media (max-width: 600px) {
                                    grid-area: right;
                                }

                                img {
                                    max-width: 100%;
                                    height: auto;
                                }
                            }

                            .widget-blog-post-text {
                                @media (max-width: 600px) {
                                    grid-area: left;
                                }

                                h3 {
                                    margin: 0;
                                    color: $black;
                                    font-family: $minion-condensed;
                                    font-size: 30px;
                                    font-weight: 500;
                                    line-height: 1.3;

                                    @media (max-width: 600px) {
                                        font-size: 22px;
                                    }
                                }

                                p.dek {
                                    margin: 10px 0;
                                    color: $black;
                                    font-family: $minion-condensed;
                                    font-size: 22px;
                                    font-weight: 400;
                                    line-height: 1.3;

                                    @media (max-width: 600px) {
                                        margin-bottom: 0;
                                        font-size: 18px;
                                        display: none;
                                    }
                                }

                                .author,
                                .published {
                                    margin-top: 0;
                                    color: $digital-grey;
                                    font-family: $proxima;
                                    font-size: 16px;
                                    font-weight: 500;
                                    display: inline-block;

                                    @media (max-width: 600px) {
                                        margin: 0;
                                    }

                                    a {
                                        color: $digital-grey;
                                    }

                                    .pub-date {
                                        @media (max-width: 600px) {
                                            margin: 0;
                                        }
                                    }
                                }

                                p.published {
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }

                .listRight {
                    @media (max-width: 1000px) {
                        display: none;
                    }

                    .widget-heading {
                        margin: 0;
                        padding: 0;
                        font-size: 28px;
                    }
                    .widget-blog-list-no-images ul {
                        margin-bottom: 60px;

                        li {
                            color: #242424;
                            font-family: $proxima;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

// Voices post
.article-template .voices-post {
    .article-columns .articleLeft .articleWrap {
        .article-line {
            grid-template-columns: 1fr 270px 300px;

            @media (max-width: 1400px) {
                grid-template-columns: 270px 1fr;
            }
        }

        .voicesIntro,
        h1.headline,
        .article-line,
        ul,
        .author-box.by-line.single-author.footer-author-box,
        .widget-heading {
            @media (max-width: 1000px) {
                padding: 0 56px;
            }

            @media (max-width: 800px) {
                padding: 0 32px;
            }

            @media (max-width: 600px) {
                padding: 0 26px;
            }
        }
    }

    .article-template
        .main-content.article
        .article-columns
        .articleLeft
        .articleWrap
        h1.headline {
        @media (max-width: 600px) {
            font-size: 32px;
        }
    }

    .article-template
        .main-content.article
        .article-columns
        .articleLeft
        .articleWrap
        p.author {
        @media (max-width: 1000px) {
            padding: 0;
        }
    }
}

.article-special-wrap.blogging {
    background: $white;
    color: $black;
    border-bottom: 1px solid #d9d9d9;

    &.blogging-single {
        text-align: center;
    }

    .article-special {
        align-items: center;

        h1,
        p {
            &.hed {
                font-weight: 400;
                letter-spacing: 0;
                a {
                    text-decoration: none;
                    color: $black;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .article-special-logo {
            img {
                width: 100% !important;
                max-width: 100% !important;
                max-height: 75px !important;
            }
        }
    }

    .article-special {
        margin: 0 auto;
        width: 1000px;
        display: grid;
        grid-template-columns: 1fr 150px;
        padding: 15px 0;
        .article-special-text:last-child:nth-last-child(odd) {
            grid-column: auto / span 2;
        }

        p {
            margin: 0;
            padding: 0;
            line-height: 1.2;
            &.hed {
                font-family: $playfair;
                font-weight: 800;
                text-transform: uppercase;
                font-size: 1.9rem;
                margin: 0;
                letter-spacing: 2px;
            }
            &.dek {
                font-family: $opensans;
                padding-bottom: 5px;
                font-size: 0.9rem;
            }
        }
        a {
            color: $white;
        }
        .article-special-logo {
            justify-self: right;
            p {
                color: #d3d3d3;
                text-transform: uppercase;
                font-family: $opensans;
                font-size: 0.7rem;
                text-align: right;
                margin-bottom: 10px;
            }
            img {
                max-width: 300px;
                max-height: 60px;
                @media (max-width: 350px) {
                    max-width: 100%;
                }
            }
        }
    }
}

.widget-blog-wrapper.blog-author-list {
    max-width: 1024px;
    margin: auto;

    @media (max-width: 600px) {
        padding: 0 26px 100px;
    }

    .museum-name-logo {
        margin-top: 60px;
        display: grid;
        grid-template-columns: 1fr 300px;
        gap: 20px;
        align-items: center;

        @media (max-width: 600px) {
            margin: 40px 0;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
        }

        .museum-name {
            font-family: $proxima;
            font-size: 22px;
            font-weight: bold;
            line-height: 1;

            @media (max-width: 600px) {
                grid-column: 1;
                grid-row: 2;
                font-size: 16px;
            }
        }

        img#voicesLogo {
            width: 300px;
            margin: 0;
            padding: 0;

            @media (max-width: 600px) {
                grid-column: 1;
                grid-row: 1;
            }
        }
    }

    h2 {
        font-family: $proxima;
        font-size: 36px;

        @media (max-width: 600px) {
            font-size: 24px;
        }
    }

    h3 {
        margin: 0 0 30px;
        font-family: $proxima;
        font-size: 28px;

        @media (max-width: 600px) {
            font-size: 24px;
        }
    }

    p.blog-description {
        font-family: $proxima;
        font-size: 20px;
    }

    .widget-blog-posting-list .widget-blog-post-text {
        margin-top: -7px;

        @media (max-width: 600px) {
            margin-top: -5px;
        }
    }

    .widget-page-right-column {
        padding: 80px 0 0 0;
        display: block;

        @media (max-width: 600px) {
            padding-top: 40px;
        }
    }
}
