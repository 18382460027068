/* overall h1 tag */
h1 {
    font-size: 44px;
    line-height: 1.3;
    font-family: $minion-condensed;
    margin: 0;
    padding: 0;
    @media (max-width: 600px) {
        font-size: 32px;
    }
    /* article headline */
    &.headline {
        font-size: 57px;
        line-height: 1.1;
        color: $black;
        font-weight: 400;
        @media (max-width: 600px) {
            font-size: 40px;
        }
    }
    /* longform article headline */
    &.longform-headline {
        font-size: 55px;
        line-height: 1.1;
        font-family: $minion;
        @media (max-width: 600px) {
            font-size: 40px;
        }
    }
}

/* overall h2 tag */
h2 {
    font-size: 30px;
    line-height: 1.3;
    font-family: $minion;
    @media (max-width: 600px) {
        font-size: 26px;
    }
}

/* overall h3 tag */
h3 {
    font-size: 22px;
    line-height: 1.3;
    font-family: $minion-condensed;
    @media (max-width: 600px) {
        font-size: 20px;
        line-height: 1.4;
    }
}

hr {
    border: none;
    border-top: 2px solid #e7e9ed;
}

/* published date */
.pub-date,
.author {
    color: $digital-grey;
    font-family: $proxima;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    margin-top: 0;

    @media (max-width: 600px) {
        margin: 10px 0 25px;
        display: block;
    }

    a {
        color: $digital-grey;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

/* entire author box */
.author-box {
    &.by-line {
        width: 100%;
        /* top author */
        &.single-author {
            display: grid;
            grid-template-columns: 80px 1fr;
            grid-column-gap: 24px;
            grid-row-gap: 0;
            align-items: center;

            @media (max-width: 600px) {
                display: block;
            }

            .author-text {
                .author {
                    margin: 0;
                    padding: 0;
                    font-family: $proxima;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 1.3;

                    a {
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .author-headshot {
                img {
                    background: $sky-blue;
                    object-fit: cover;
                    border-radius: 50%;
                    width: 80px;
                    height: 80px;
                }
            }
            /* footer author box */
            &.footer-author-box {
                display: grid;
                grid-template-columns: 136px 1fr !important;
                grid-gap: 40px;
                align-items: center;
                margin: 40px 0;

                @media (max-width: 600px) {
                    text-align: center;
                    display: block;
                }

                &:first-of-type {
                    margin-top: 80px;
                }

                /* footer author photo */
                .author-headshot {
                    img {
                        background: $sky-blue;
                        object-fit: cover;
                        border-radius: 50%;
                        width: 136px;
                        height: 136px;
                    }
                }
                /* footer author bio text */
                .author-bio-text {
                    margin-top: 5px;
                    font-family: $proxima;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 1.4;

                    @media (max-width: 600px) {
                        margin-top: 20px;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                /* author bio box on list page */
                &.author-list-page {
                    align-items: center;

                    .author-text {
                        padding-bottom: 0;
                    }

                    h1 {
                        margin: 0;
                        padding: 0;
                        border-bottom: 0;
                        font-family: $proxima;
                        font-size: 36px;
                        line-height: 0;
                        display: inline-block;

                        @media (max-width: 600px) {
                            margin-top: 1rem;
                        }
                    }

                    .author-bio-text {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border-bottom: 0;
                        font-size: 20px;
                    }

                    .author-socials {
                        margin: 10px 0;

                        @media (max-width: 600px) {
                            text-align: center;
                        }

                        i {
                            width: 32px;
                            height: 32px;
                            background-color: $black;
                            border-radius: 100%;
                            color: $white;
                            font-size: 20px;
                            text-align: center;
                            display: grid;
                            place-items: center;

                            @media (max-width: 600px) {
                                margin: auto;
                            }
                        }
                    }
                }
            }
        }

        &.no-photo {
            display: block !important;

            img {
                display: none;
            }
        }
    }
}

/* tagline */
.tagline {
    /* tagline 1 */
    &.tagline-one {
        font-family: $minion;
        font-size: 24px;
        line-height: 1.4;
        @media (max-width: 600px) {
            font-size: 20px;
        }
    }
    /* tagline 2 */
    &.tagline-two {
        font-family: $minion;
        font-size: 22px;
        line-height: 1.3;
    }
    /* article tagline */
    &.article-tagline {
        font-family: $minion;
        font-size: 32px;
        font-weight: 400;
        line-height: 1.3;
        padding: 18px 0 28px;
        margin: 0;
        @media (max-width: 600px) {
            font-size: 24px;
        }
    }
}

.tag-list {
    margin-bottom: 200px;
    margin-top: 80px;
    .nav-tags {
        font-family: $proxima;
        font-size: 18px;
        font-weight: 700;
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            li {
                display: inline-grid;
                padding: 16px;
                background: $button;
                margin-bottom: 16px;
                &:first-of-type {
                    background: $white;
                    padding-left: 0;
                }
            }
        }
        a {
            font-weight: 400;
        }
    }
}

/* image captions */
figure {
    img {
        width: 100%;
        height: auto;
        &.left {
            float: left;
            width: 50%;
            height: auto;
        }
        &.right {
            float: right;
            width: 50%;
            height: auto;
        }
    }
    &.article-image {
        margin: 48px 0;
        @media (max-width: 1000px) {
            padding: 0 56px;
        }
        @media (max-width: 800px) {
            padding: 0 32px;
        }
        @media (max-width: 600px) {
            padding: 0 26px;
        }

        &.lead-article-image {
            padding-top: 0;
            @media (max-width: 1000px) {
                padding: 0;
            }
            figcaption {
                &.caption {
                    @media (max-width: 1000px) {
                        padding: 24px 56px 0;
                    }
                    @media (max-width: 800px) {
                        padding: 24px 32px 0;
                    }
                    @media (max-width: 600px) {
                        padding: 0 26px;
                    }
                }
            }
        }

        &.left {
            float: left;
            width: 307px;
            margin-right: 44px;
            margin-top: 0;
            padding-top: 7px;

            @media (max-width: 800px) {
                width: 100%;
                margin-right: auto;
                float: none;
                display: block;
            }
        }

        &.right {
            float: right;
            width: 307px;
            margin-left: 44px;
            margin-top: 0;
            padding-top: 7px;

            @media (max-width: 800px) {
                width: 100%;
                margin-left: auto;
                float: none;
                display: block;
            }
        }

        &.left_diptych {
            float: left;
            width: 48%;
            margin-top: 0;
            padding-top: 7px;
        }

        &.right_diptych {
            float: right;
            width: 48%;
            margin-top: 0;
            padding-top: 7px;
        }

        &.left_diptych,
        &.right_diptych {
            @media (max-width: 1100px) {
                width: 47.5vw;
                width: auto;
                margin-left: 0;
                margin-right: 0;
                float: none;
            }
        }

        &.right_diptych + * {
            clear: both;
        }

        &.right + p {
            clear: left;
        }

        &.left + p {
            clear: unset;
        }

        &.left {
            margin-top: 25px;
        }
    }
    img {
        display: block;
    }
    &.video {
        margin: 0;
        padding: 20px 0;
        .credit {
            margin-top: 4px;
        }
        .embedly-plugin {
            overflow: hidden;
            .video-image {
                transition: opacity 0.5s;
                &:after {
                    content: '';
                    width: 75px;
                    height: 60px;
                    line-height: 0.5;
                    margin: auto;
                    font-size: 50px;
                    font-weight: 400;
                    text-align: center;
                    position: absolute;
                    top: 50%;
                    bottom: 50%;
                    left: 0;
                    right: 0;
                    z-index: 3;
                    pointer-events: none;
                    background: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/51/fa/51faae38-52d7-4911-b53b-d2a907487bc7/youtube_play_button_icon.svg')
                        top left no-repeat;
                    @media (max-width: 600px) {
                        width: 50px;
                        height: 50px;
                    }
                }
                .youtube-video-title {
                    color: $white;
                    line-height: 1.5;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 3;
                    width: 100%;
                    padding: 15px 15px 25px 15px;
                    background: rgb(0, 0, 0);
                    background: linear-gradient(
                        180deg,
                        rgba(0, 0, 0, 1) 0%,
                        rgba(255, 255, 255, 0) 100%
                    );
                    box-sizing: border-box;
                    font-family: $proxima;
                    font-weight: 700;
                    font-size: 18px;
                    @media (max-width: 600px) {
                        font-size: 14px;
                    }
                }
                .watch-on-youtube {
                    padding: 10px 20px;
                    background-color: rgba(0, 0, 0, 0.7);
                    line-height: 1.5;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    z-index: 3;
                    font-family: $proxima;
                    img {
                        margin: 0;
                        padding: 0;
                        display: inline-block;
                        padding-left: 5px;
                        position: relative;
                        top: 2px;
                        width: 70px;
                        height: auto;
                    }
                    a {
                        color: $white;
                    }
                    @media (max-width: 600px) {
                        padding: 0 10px;
                        a {
                            font-size: 15px;
                        }
                    }
                }
                img {
                    min-height: 100%;
                    max-height: 100%;
                    position: absolute;
                    cursor: pointer;
                    z-index: 2;
                    @media (max-width: 1100px) {
                        max-height: 35.7vw;
                    }
                    @media (max-width: 1000px) {
                        max-height: 53vw;
                    }
                }
            }
            iframe {
                z-index: 1;
            }
        }
    }
    &.pullquote {
        position: relative;
        font-family: $minion;
        font-size: 32px;
        font-weight: 500;
        line-height: 1.2;
        margin-left: 136px;
        margin-right: 0;
        margin-top: 60px;
        margin-bottom: 60px;
        &:before {
            content: '';
            width: 80px;
            height: 69px;
            line-height: 0.5;
            position: absolute;
            margin-left: -136px;
            top: 5px;
            left: 0;
            z-index: 3;
            pointer-events: none;
            background: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/c4/01/c401b7dd-7380-48cc-abb7-ff128425de2f/blockquote.svg')
                top left no-repeat;
        }
        p {
            margin: 0;
            padding: 0;
            font-size: 32px;
            font-weight: 500;
            line-height: 1.2;
        }
        &.left {
            float: left;
            width: 307px;
            margin-right: 44px;
            margin-top: 0;
            padding-top: 7px;
        }
        &.right {
            float: right;
            width: 307px;
            margin-left: 166px;
            margin-top: 0;
        }
    }
}

figcaption {
    &.caption {
        padding: 24px 0 0 0;
        font-family: $proxima;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        @media (max-width: 1000px) {
            padding: 24px 0 0;
        }
        a {
            text-decoration: underline;
            color: $black;
            &:hover {
                text-decoration: none;
            }
        }
        .credit {
            color: $digital-grey;
            a {
                color: $digital-grey;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

/* slideshow */
.slideshow-wrapper {
    margin: 60px 0;
    padding: 50px 0 0;
    .slideshow {
        position: relative;
        .slideshow-prev:hover,
        .slideshow-next:hover {
            text-decoration: none;
        }
        .fa-chevron-left,
        .fa-chevron-right,
        .fa-chevron-square-left,
        .fa-chevron-square-right {
            color: #333;
            font-size: 3rem;
            cursor: pointer;
            &:hover {
                color: $black;
                text-decoration: none;
            }
        }
        .fa-chevron-right,
        .fa-chevron-square-right {
            margin-left: 10px;
            left: inherit;
            right: 80px;
        }
        .slideshow-slides {
            .slide {
                display: none;
                figure {
                    width: 100%;
                    margin: 0 auto;
                    img {
                        width: 100%;
                    }
                }
                &:first-of-type {
                    display: block;
                }
            }
        }
        .slide-info {
            width: 100%;
            display: grid;
            grid-template-columns: 136px 1fr 136px;

            @media (max-width: 800px) {
                grid-template-columns: 50px 1fr 50px;
            }

            .left-navigation {
                margin-top: 24px;
            }
            .right-navigation {
                text-align: right;
                margin-top: 24px;
            }
            .slide-number {
                .current-slide {
                    font-weight: bold;
                }
            }
            .slide-details {
                align-items: center;
                .caption {
                    padding-bottom: 26px;
                }
                .slide-number {
                    text-align: center;
                    font-family: $proxima;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 1;
                    margin-bottom: 26px;
                }
                .slide-markers {
                    text-align: center;

                    .greybar {
                        width: 32px;
                        height: 8px;
                        background: $divider;
                        display: inline-block;

                        &.active {
                            background: $black;
                        }
                    }
                }
            }
        }
    }
}

/* page section */
.page-section {
    /* page section 1 */
    &.section-one {
        font-family: $proxima;
        font-size: 32px;
        line-height: 1.3;
        @media (max-width: 600px) {
            font-size: 24px;
            line-height: 1.2;
        }
    }
    /* page section 2 */
    &.section-two {
        font-family: $proxima;
        font-size: 28px;
        line-height: 1.3;
    }
}

/* series tag */
.series {
    font-family: $proxima;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.3;
    margin: -3px 0 10px;

    @media (max-width: 600px) {
        font-size: 16px;
    }
}

/* buttons */
.button,
button,
input[type='submit'] {
    background: $black;
    color: $white;
    font-family: $proxima;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.3;
    width: 100%;
    max-width: 300px;
    margin: 16px 0;
    border: 0;
    text-align: center;
    a {
        display: block;
        padding: 15px 0;
        color: $white;
    }
    &.white {
        border: 3px solid $black;
        color: $black;
        background: $white;
        a {
            color: $black;
        }
        &.center {
            text-align: center;
            margin: 0 auto;
            display: block;
        }
    }
    &.center {
        text-align: center;
        margin: 0 auto;
        display: block;
    }
}

.button {
    @media (max-width: 600px) {
        width: 100%;
        max-width: 340px;
    }
}

input[type='submit'] {
    padding: 15px 0;
}

/* category nav */
.category-section-menu {
    width: $desktop_large;
    margin: 0 auto;

    h1 {
        font-family: $proxima;
        color: $black;
        font-size: 32px;
        font-weight: 700;
        line-height: 130%;

        @media (max-width: 600px) {
            font-size: 24px;
        }
    }

    nav {
        ul {
            margin: 0;
            padding: 24px 0;
            border-bottom: 1px solid $divider;
            list-style-type: none;

            li {
                font-family: $proxima;
                font-size: 18px;
                font-weight: 700;
                line-height: 1;
                margin: 0 48px 20px 0;
                font-style: italic;
                display: inline-flex;

                @media (max-width: 600px) {
                    margin: 0 20px 10px 0;
                    font-size: 14px;
                }
            }
        }
    }
}

/* content featured list */
.content-featured-list {
    display: grid;
    grid-template-columns: 300px repeat(1, 1fr) 300px;
    gap: 48px;

    @media (max-width: 1200px) {
        grid-template-columns: 250px repeat(1, 1fr) 250px;
        gap: 28px;
    }

    .first {
        grid-column: 2;
        grid-row: 1 / span 2;
        text-align: center;

        @media (max-width: 600px) {
            text-align: left;
        }

        h3 {
            font-size: 44px;
            font-weight: 500;
            line-height: 1.3;
            margin: 22px 0 0 0;

            @media (max-width: 1200px) {
                font-size: 35px;
                line-height: 1.1;
            }

            @media (max-width: 600px) {
                font-size: 32px;
            }
        }

        p {
            &.dek {
                font-family: $minion-condensed;
                font-size: 24px;
                font-weight: 400;
                line-height: 1.4;

                @media (max-width: 1200px) {
                    font-size: 22px;
                    line-height: 1.1;
                }

                @media (max-width: 600px) {
                    font-size: 20px;
                }
            }

            &.author {
                color: $digital-grey;
                font-family: $proxima;
                font-size: 16px;
                font-weight: 500;
                line-height: 1.4;

                a {
                    color: $digital-grey;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        img {
            @media (max-width: 600px) {
                padding: 0;
                max-width: 100%;
            }
        }
    }

    img {
        width: 100%;
        height: auto;
        display: block;
        @media (max-width: 600px) {
            padding: 0 24px;
            max-width: 90%;
        }
        @media (max-width: 450px) {
            max-width: 88%;
        }
        @media (max-width: 400px) {
            max-width: 87%;
        }
    }

    h3 {
        font-size: 30px;
        font-weight: 500;
        line-height: 1.3;
        margin: 13px 0;

        @media (max-width: 1200px) {
            font-size: 25px;
            line-height: 1.1;
        }
        @media (max-width: 600px) {
            padding: 0 24px;
        }
    }
    p {
        @media (max-width: 600px) {
            padding: 0 24px;
        }
    }
}

/* content list */
.content-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 48px;

    @media (max-width: 600px) {
        display: block;
    }

    h2 {
        font-family: $proxima;
        font-size: 28px;
    }

    .content-list-columns {
        display: grid;
        grid-template-columns: 1fr 300px;
        gap: 48px;

        @media (max-width: 600px) {
            display: block;
            padding: 0 24px 32px;
        }
        @media (max-width: 450px) {
            max-width: 88%;
        }
        @media (max-width: 400px) {
            max-width: 87%;
        }

        .content-list {
            .content-list-item {
                display: grid;
                grid-template-columns: 324px 1fr;
                gap: 48px;

                @media (max-width: 1200px) {
                    grid-template-columns: 230px 1fr;
                    gap: 32px;
                }

                @media (max-width: 600px) {
                    padding-bottom: 24px;
                    gap: 20px;
                    grid-template-columns: 1fr 126px;

                    .content-list-text {
                        order: 1;
                    }

                    .content-list-image {
                        order: 2;
                    }
                }

                img {
                    width: 100%;
                    height: auto;
                    max-width: 314px;
                    @media (max-width: 600px) {
                        object-position: center;
                        object-fit: cover;
                        width: 100%;
                        height: 123px;
                    }
                }
                h3 {
                    margin: 0;
                    font-family: $minion-condensed;
                    font-size: 25px;
                    font-weight: 500;
                    line-height: 1.3;
                    @media (max-width: 400px) {
                        font-size: 20px;
                        line-height: 1.2;
                    }
                }
                p {
                    &.dek {
                        font-family: $minion;
                        font-size: 22px;
                        font-weight: 400;
                        line-height: 1.3;
                        margin: 15px 0;
                        @media (max-width: 400px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

/* affiliate link treatment */
.affiliateLink {
    background: $button;
    padding: 32px 40px;
    font-family: $proxima;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
    display: grid;
    grid-template-columns: 37px 1fr;
    gap: 32px;
    align-items: center;
    p {
        background: $button;
        margin: 0;
        padding: 0;
        font-family: $proxima !important;
        font-size: 18px !important;
        font-weight: 400 !important;
        line-height: 1.4 !important;
    }
}

/* social media */
.instagram-media,
.twitter-tweet {
    margin: 0 auto;
}

/* podcast player */
.in-article-podcast-player {
    margin-bottom: 60px;
}

/* newsletter box */
.in-article-newsletter,
.home-newsletter {
    margin: 80px 0;
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .leade {
        display: block;
        h3 {
            font-family: $proxima !important;
            font-size: 32px !important;
            font-weight: 700 !important;
            line-height: 1 !important;
            margin: 0;
            padding: 0;
            @media (max-width: 600px) {
                margin-bottom: 20px;
                font-size: 1.3rem;
                margin-bottom: 5px;
                display: block;
            }
        }
        form {
            text-align: left;
            label {
                display: block;
                font-family: $proxima;
                font-size: 18px;
                font-weight: 700;
                line-height: 1;
                padding: 24px 0;
            }
            input[type='email'] {
                border: 2px solid $black;
                color: $digital-grey;
                background: $white;
                padding: 16px 24px;
                letter-spacing: 1px;
                width: 73%;
                margin-top: 4px;

                @media (max-width: 1200px) {
                    width: 62%;
                }

                @media (max-width: 800px) {
                    width: 60%;
                }

                @media (max-width: 600px) {
                    width: 100%;
                    margin: 0 auto;
                    display: block;
                    box-sizing: border-box;
                }
            }
            input[type='submit'] {
                margin-top: 3px;
                // width: 217px;
                width: 21%;
                background: $black;
                padding: 16px 0 17px 0;
                border: 0;
                color: $white;
                position: relative;
                top: 1px;
                margin-left: -5px;
                cursor: pointer;
                font-family: $proxima;
                font-size: 18px;
                font-weight: 700;
                line-height: 1;

                @media (max-width: 1357px) {
                    width: 20.5%;
                }
                @media (max-width: 1289px) {
                    width: 19.5%;
                }
                @media (max-width: 1200px) {
                    width: 28%;
                }
                @media (max-width: 1000px) {
                    width: 30%;
                }

                @media (max-width: 600px) {
                    width: 100%;
                    max-width: none;
                    margin: 4px auto 0;
                    display: block;
                    box-sizing: border-box;
                }
            }
        }
        .twocol {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            input {
                border: 2px solid $black;
                color: $digital-grey;
                background: $white;
                padding: 16px 24px;
                letter-spacing: 1px;
                width: 88%;

                @media (max-width: 1200px) {
                    width: 85%;
                }

                @media (max-width: 800px) {
                    width: 85%;
                }

                @media (max-width: 600px) {
                    width: 100%;
                    margin: 0 auto;
                    display: block;
                    box-sizing: border-box;
                    margin-bottom: 5px;
                }
            }
        }
    }
    .terms-info {
        // padding-top: 16px !important;
        padding-top: 0 !important;
        font-family: $proxima !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 1.4 !important;
        margin-top: 0;

        @media (max-width: 600px) {
            text-align: left;
            padding-bottom: 0;
            margin: 0;
            padding-top: 16px !important;
        }

        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

/* promoted product */
.article-template
    .main-content.article
    .article-columns
    .articleLeft
    .articleWrap
    .binding-box {
    border-top: 1px solid $divider;
    border-bottom: 1px solid $divider;
    margin: 60px 0;
    .embedly-retail,
    .amazon-associated-product {
        display: grid;
        grid-template-columns: 215px 1fr;
        grid-gap: 50px;
        padding: 20px 20px 20px 0;
        align-items: center;

        @media (max-width: 600px) {
            padding-right: 0;
            text-align: left;
            display: block;
        }

        img {
            width: 215px;
            height: auto;

            @media (max-width: 600px) {
                margin: 0 auto 30px;
                display: block;
            }
        }

        h3 {
            font-family: $minion-condensed;
            font-size: 32px;
            font-weight: 500;
            line-height: 1;
            margin-top: 0;

            @media (max-width: 600px) {
                margin-top: 10px;
            }

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        p {
            font-family: $minion;
            font-size: 24px;
            font-weight: 400;
            line-height: 1.4;
        }

        p.associated-caption {
            padding: 0;
        }

        .btn,
        button {
            padding: 15px;

            @media (max-width: 600px) {
                width: 100%;
                max-width: none;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
    &.nobottom {
        border-bottom: 0;
    }
    h2 {
        text-transform: uppercase;
        font-family: $opensans;
        letter-spacing: 0.15rem;
        margin-bottom: 10px;
        font-size: 1.15rem;
        span {
            border-bottom: 2px solid $black;
        }
    }
    .list-no-images {
        padding: 0 20px 20px;
        margin-top: -36px;
        .list-elements {
            padding: 20px 0 5px;
            h3 {
                font-weight: 400;
                line-height: 1.35rem;
                margin: 4px 0 22px 0;
                padding: 0;
                &:last-of-type {
                    margin: 4px 0 10px 0;
                }
            }
            .section {
                line-height: 0.85rem;
                margin: 0;
                padding: 0;
            }
            hr {
                border: 0;
                &:before {
                    background: $black;
                    height: 2px;
                    width: 60%;
                    content: '';
                    display: inline-block;
                    vertical-align: top;
                }
            }
        }
    }
}

/* pagination */
.paginationSection {
    display: grid;
    grid-template-columns: 150px 1fr;
    align-items: center;
    font-family: $proxima;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;

    @media (max-width: 1000px) {
        display: block;
    }
    @media (max-width: 600px) {
        font-size: 14px;
    }

    .columns {
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            @media (max-width: 800px) {
                text-align: center;
            }

            li {
                display: inline-flex;

                a {
                    display: block;
                    color: $black;
                    padding: 6px 12px;

                    @media (max-width: 800px) {
                        padding: 6px 8px;
                    }

                    &.active {
                        background: $black;
                        color: $white;
                    }

                    &:hover {
                        background: $black;
                        color: $white;
                        text-decoration: none;
                    }
                }
            }
        }

        &:last-of-type {
            text-align: right;

            @media (max-width: 800px) {
                text-align: left;
            }
        }
    }
}

.widget-related-articles {
    border-top: 1px solid $divider;
    border-bottom: 1px solid $divider;
    padding: 32px 0;
    margin-top: 60px;
    h3 {
        font-family: $proxima !important;
        font-size: 32px !important;
        font-weight: 700 !important;
        margin: 0;
        padding: 0;
        padding-bottom: 12px;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
            padding-bottom: 12px !important;
            a {
                font-family: $minion-condensed;
                font-size: 22px;
                color: $black;
                text-decoration: none !important;
                &:hover {
                    text-decoration: underline !important;
                }
            }
            .pub_date {
                color: $digital-grey;
                font-family: $proxima;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 1.4;
            }
        }
    }
}
