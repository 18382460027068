// Flatpage
.flatpage {
    .main-content {
        max-width: 1100px;
        margin: 50px auto 100px;
        @media (max-width: 1100px) {
            width: auto;
        }
        h1 {
            margin: 0 0 24px;
            font-size: 57px;
            line-height: 110%;
            font-weight: normal;
            @media (max-width: 450px) {
                font-size: 32px;
            }
        }
        h2 {
            margin: 0 0 20px;
            font-size: 2.3rem;
            font-weight: normal;
        }
        h3 {
            margin: 0 0 16px;
            font-size: 1.7rem;
            font-weight: normal;
        }
        p {
            margin: 0 0 25px;
            font-family: $minion;
            font-size: 22px;
            line-height: 165%;
            a {
                text-decoration: underline;
            }
        }
        ul,
        ol {
            font-family: $opensans;
            font-size: 1.1rem;
            line-height: 1.85;
        }
        .two-column-grid {
            margin-bottom: 50px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 50px;

            @media (max-width: 1000px) {
                grid-template-columns: 1fr;
                gap: 20px;
            }
        }
        .three-column-grid {
            margin-bottom: 50px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 50px;

            @media (max-width: 1000px) {
                gap: 20px;
            }

            @media (max-width: 800px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 600px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .three-column-grid-item {
                img {
                    width: 100%;
                }

                .title {
                    font-size: 1.5rem;
                    font-weight: 700;
                }
            }
        }
        .links-box {
            padding: 30px 40px;
            border: 3px solid $black;
            font-family: $opensans;
            font-size: 1.1rem;
            word-break: break-word;

            @media (max-width: 400px) {
                padding: 15px 20px;
            }
        }
        .black-box {
            height: 60px;
            padding: 20px 50px;
            background: $black;
            color: $white;
            font-family: $proxima;
            font-size: 1.2rem;
            font-weight: bold;
            text-align: center;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .contact-info {
            font-weight: 700;
            padding-bottom: 32px;
            p {
                font-family: $proxima;
                font-size: 19px;
                line-height: 1.9;
                margin: 0;
                a {
                    text-decoration: none;
                }
            }
        }
        .contact-sf-buttons {
            height: 60px;
            // padding: 20px 50px;
            border: 3px solid $black;
            color: $black;
            font-family: $proxima;
            font-size: 1.2rem;
            font-weight: bold;
            text-align: center;
            line-height: 1.3;
            // display: flex;
            align-items: center;
            justify-content: center;
            width: 44%;
            display: inline-flex;
            margin-right: 4%;
            padding: 0;

            @media (max-width: 920px) {
                width: 100%;
                margin: 0 0 12px;
            }
            @media (max-width: 800px) {
                width: 44%;
                margin: 0 4% 0 0;
            }
            @media (max-width: 650px) {
                width: 100%;
                margin: 0 0 12px;
            }
            @media (max-width: 450px) {
                width: 98%;
            }
        }
        // FAQ Questions and Answers
        .faQsAndAs {
            @media (max-width: 450px) {
                max-width: 95%;
            }
            p {
                font-family: $proxima;
                @media (max-width: 450px) {
                    font-size: 20px;
                }
            }
            .faQuestion {
                font-family: $proxima;
                font-weight: 700;
                background-color: $button;
                padding: 40px 0 16px 24px;
                margin-top: 80px;
                margin-bottom: 24px;
            }
        }
    }
    .sidebar-page {
        margin: 60px auto;
        display: grid;
        grid-template-columns: 7fr 3fr;
        gap: 48px;

        @media (max-width: 1000px) {
            gap: 20px;
        }

        @media (max-width: 800px) {
            grid-template-columns: 1fr;
        }

        .sidebar {
            a {
                margin: 0 0 8px;
                font-family: $proxima;
                font-size: 18px;
                display: block;
            }
        }
    }
    .grid-element {
        .grid-box {
            background: $button;
            color: $black;
            h3 {
                font-size: 1.2rem;
                font-weight: 600;
                margin: 0;
                padding: 0;
                line-height: 1.4rem;
                span {
                    font-weight: 400;
                }
            }
            a {
                color: $black;
                min-height: 50px;
                padding: 20px 30px;
                font-family: $proxima;
                font-size: 1.2rem;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        p {
            font-size: 18px;
            line-height: 140%;
            padding: 10px 0px;
            margin-bottom: 0;
            font-family: $proxima;
            &.start {
                a {
                    font-weight: 700;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

// styles for about sidebar menu that appears on about sub-pages in v3
.about-sidebar {
    .about-social-links {
        h3 {
            font-family: $proxima;
            font-size: 18px;
            font-weight: bold;
        }
        span {
            background: $black;
            color: $white;
        }
        span:hover {
            background: $white;
            color: $black;
            border: 2px solid $black;
            a {
                color: $black;
            }
        }
        a {
            height: 24px;
            color: $white;
        }
    }
    .sidebar {
        h3 {
            background-color: $black;
            color: $white;
            padding: 12px 20px;
            font-family: $proxima;
            font-size: 28px;
            font-weight: bold;
        }
        a {
            font-size: 18px;
            font-weight: bold;
            padding: 0px 20px;
            font-family: $proxima;
        }
        .sidebar-sections {
            padding-bottom: 32px;
        }
    }
}
